import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
} from 'material-react-table';
import {
  Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import {
  getReportVehicles,
} from '../../actions/apiGetInfo';
import { convertSecondsToTime, noteStamp } from '../../utils/dateFunctions';
import DepartmentListItemContainer from './DepartmentListItemContainer';

// Define columns using createMRTColumnHelper
const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    size: 120,
  }),
  columnHelper.accessor('display_name', {
    header: 'Display Name',
  }),
  columnHelper.accessor('order', {
    header: 'Order',
  }),
  columnHelper.accessor('goal', {
    header: 'Goal',
  }),
  columnHelper.accessor('department_type', {
    header: 'Department Type',
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const exportableColumnKeys = ['name', 'display_name', 'order', 'goal', 'department_type'];

const ExportCSV = (props) => {
  const globalTheme = useTheme();
  const [expandedRow, setExpandedRow] = useState(null);

  const tableTheme = useMemo(() => createTheme({
    palette: {
      mode: 'light',
    },
  }), [globalTheme]);

  // Prepare data for the table
  const data = useMemo(() => {
    return props.departments.map((department) => ({
      name: department.name,
      display_name: department.display_name,
      order: department.order,
      goal: department.goal,
      department_type: department.department_type,
      subgroup: department.subgroup,
      subdomain_department: department.subdomain_department,
      subdomain: department.subdomain,
      department_id: department.id,
      department_dealerships: department.department_dealerships,
      dealerships: department.dealerships,
      department_filters: department.department_filters,
      department_properties: department.department_properties,
      background_color: department.background_color,
      badge_background_color: department.badge_background_color,
      badge_text_color: department.badge_text_color,
      text_color: department.text_color,
      hours_profile_id: department.hours_profile_id,
      is_hours: department.is_hours,
    }));
  }, [props.departments]);

  const handleExportRowsToCSV = (rows) => {
    const rowData = rows.map(row => exportableColumnKeys.reduce((acc, key) => {
      acc[key] = row.original[key];
      return acc;
    }, {}));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportDataToCSV = () => {
    const rowData = data.map(item => exportableColumnKeys.reduce((acc, key) => {
      acc[key] = item[key];
      return acc;
    }, {}));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRowsToPDF = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map(row => exportableColumnKeys.map(key => row.original[key]));
    const tableHeaders = exportableColumnKeys.map(key => columns.find(col => col.accessorKey === key).header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('report.pdf');
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: false,
    enableRowOrdering: true,
    initialState: { showColumnFilters: true },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRowsToCSV(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export to CSV
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRowsToCSV(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows to CSV
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRowsToPDF(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export to PDF
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRowsToPDF(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows to PDF
        </Button>
      </Box>
    ),
    muiRowDragHandleProps: {
      onDragEnd: (event, data) => {
        // Handle row drag end
      },
    },
    detailPanel: [
      {
        open: rowData => rowData.tableData.id === expandedRow,
        render: rowData => {
          if (rowData.tableData.id !== expandedRow) {
            setExpandedRow(null);
          }
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <DepartmentListItemContainer department_id={rowData.original.department_id} />
            </Box>
          );
        },
        onClick: (event, rowData) => {
          setExpandedRow(
            expandedRow === rowData.tableData.id ? null : rowData.tableData.id,
          );
        },
      },
    ],
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

const mappedActions = {
  getReportVehicles,
  convertSecondsToTime,
  noteStamp,
};

function mapStateToProps(state) {
  return {
    departments: state.settings_departments.original_departments,
  };
}

export default connect(mapStateToProps, mappedActions)(ExportCSV);
