import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
} from 'material-react-table';
import {
  Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  getReportVehicles,
} from '../../../../actions/apiGetInfo';
import { convertSecondsToTime, getSecondsFromTimeRange_24_5, noteStamp } from '../../../../utils/dateFunctions';
import NewEstimateSingle from './TransportSingle';
import {
  addEstimate,
} from '../../../../actions/apiEstimates';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor('action', {
    header: 'Action',
    Cell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <NewEstimateSingle estimate={row.original} />
      </Box>
    ),
  }),
  columnHelper.accessor('requested_by_uid', {
    header: 'Recipients',
  }),
  columnHelper.accessor('delete', {
    header: 'Delete',
    Cell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'left',
          gap: '1rem',
        }}
      >
        <button className="btn btn-sm btn-danger" onClick={() => onDelete(row.original)}>X</button>
      </Box>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const Transport = (props) => {
  const globalTheme = useTheme();

  const tableTheme = useMemo(() => createTheme({
    palette: {
      mode: 'light',
    },
  }), [globalTheme]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(newdata);
    download(csvConfig)(csv);
  };

  const newdata = useMemo(() => {
    return props.appraisals
      .filter(estimate => estimate.ro_status === 'transport')
      .map(estimate => ({
        requested_by_uid: estimate.requested_by_uid,
        action: estimate.ro_status,
      }));
  }, [props.appraisals]);

  const onDelete = (estimate) => {
    props.deleteEstimate(estimate.id, props.active_vehicle.id, 'addDeleteEstimateError');
  };

  const onClick = () => {
    const data = {
      vehicle_id: props.active_vehicle.id,
      ro_status: 'transport',
    };
    props.addEstimate(data, 'addEstimateError');
  };

  const table = useMaterialReactTable({
    columns,
    data: newdata,
    positionToolbarAlertBanner: 'bottom',
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      />
    ),
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <div className="card-header">
        <Button className="btn btn-sm btn-success" onClick={onClick}>
          Add Transport
        </Button>
      </div>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

const mappedActions = {
  addEstimate,
};

function mapStateToProps(state) {
  return {
    active_vehicle: state.settings_refresh.active_vehicle,
    appraisals: state.settings_refresh.appraisals,
    installation: state.settings_dealerships.installation,
    all_tabs: state.settings_site.all_tabs,
  };
}

export default connect(mapStateToProps, mappedActions)(Transport);
