import React, { Component, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
 MaterialReactTable, MRT_GlobalFilterTextField, MRT_ToggleFiltersButton, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton,
} from 'material-react-table';
import {
 Box, Button, createTheme, ThemeProvider, useTheme, MenuItem, ListItemIcon,
} from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteWorkItemPresetButton from './DeleteWorkItemPresetButton';
import { lookupVehicleById } from '../../actions/lookups';
import WorkItemPreset from './WorkItemPreset';
import AddWorkItemPreset from './AddWorkItemPreset';

const WorkItemDashboard = (props) => {
  const columns = [
    {
  	  accessorKey: 'edit',
  	  header: 'Edit',
  	  Cell: ({ renderedCellValue, row }) => (
        <Box
      	  sx={{
      		display: 'flex',
      		justifyContent: 'space-around',
      		alignItems: 'right',
      	  }}
      	>  {console.log('Edit Value',renderedCellValue)}
          <div className="button"><AddWorkItemPreset {...props} workItem={renderedCellValue} /></div>
        </Box>
  		  ),
  	},
  	{
  	  accessorKey: 'name',
  	  header: 'name',
  	},
  	{
  		accessorKey: 'description',
  		header: 'Description',
  	  },
  	  {
  		accessorKey: 'category',
  		header: 'Work Item Type',
  	  },
  	  {
  		accessorKey: 'estimate_type',
  		header: 'Type',
  	  },
  	{
  	  accessorKey: 'parts_price',
  	  header: 'Parts Price',
  	},
  	{
  		accessorKey: 'labor_units',
  		header: 'Labor Unit',
  	  },
  	  {
  		accessorKey: 'labor_price',
  		header: 'Labor Price',
  	  },
  	  {
  		accessorKey: 'estimate_total',
  		header: 'Total',
  	  },
  	{
  	  accessorKey: 'delete',
  	  header: 'Delete',
  	  Cell: ({ renderedCellValue, row }) => (
  		<Box
  				  sx={{
  					display: 'flex',
  					alignItems: 'center',
  					gap: '1rem',
  				  }}
  		>
  				  <DeleteWorkItemPresetButton work_item_preset_id={row.original.id} />
  				</Box>
  			  ),
  	},
    ];


	const globalTheme = useTheme();

	  const tableTheme = useMemo(
	    () => createTheme({
	        palette: {
	          mode: 'light',
	        },
	      }),
	    [globalTheme],
	  );

  	const newdata = [];

	props.work_item_presets.map(work_item_preset => {
		newdata.push({
      edit: work_item_preset,
			name: [work_item_preset.name],
			description: [work_item_preset.description],
			category: [work_item_preset.category],
			estimate_type: [work_item_preset.estimate_type],
			labor_units: [work_item_preset.labor_units],
			labor_price: [work_item_preset.labor_price],
			parts_price: [work_item_preset.parts_price],
			estimate_total: [work_item_preset.estimate_total],
			id: [work_item_preset.id],
		});
    });

	const tableRef = useRef();
		  return (
	<ThemeProvider theme={tableTheme}>
<MaterialReactTable
tableRef={tableRef}
columns={columns}
data={newdata}
positionToolbarAlertBanner="bottom"
renderTopToolbar={({ row }) => (
	<Box
	  sx={{
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'right',
	  }}
	><div className="button"><AddWorkItemPreset {...props} /></div></Box>
	)}
renderDetailPanel={({ row }) => (
<Box
  sx={{
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
  }}
><WorkItemPreset work_item_preset={row} /> </Box>
)}
/></ThemeProvider>
);
};

const mappedActions = {
	lookupVehicleById,
};

function mapStateToProps(state) {
	return {
		work_item_presets: state.settings_refresh.work_item_presets,
	};
}
export default connect(mapStateToProps, mappedActions)(WorkItemDashboard);
