import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
} from 'material-react-table';
import {
  Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Modal from 'react-modal';
import { getReportVehicles } from '../../actions/apiGetInfo';
import {
  lookupSoldVehicleById,
  lookupVehicleByIdAllVehicles,
  lookupWorkFlowById,
} from '../../actions/lookups';
import ReportVehiclesModal from './ReportWorkFlowVehiclesModal';

const TableWorkFlow = (props) => {
  const globalTheme = useTheme();

  const tableTheme = useMemo(() => createTheme({
    palette: {
      mode: 'light',
    },
  }), [globalTheme]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState({ report_id: 0, department_id: 0 });

  const openModal = (report_id, department_id) => {
    if (report_id > 0) {
      props.getReportVehicles(report_id);
      setSelectedReport({ report_id, department_id });
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor('dealership', {
      header: 'Dealership',
      size: 120,
    }),
    columnHelper.accessor('department', {
      header: 'WorkFlow',
	  accessorFn: (row) => props.lookupWorkFlowById(row.department_id)?.name
    }),
    columnHelper.accessor('average_time', {
      header: 'Avg Days',
    }),
    columnHelper.accessor('number_of_vehicles', {
      header: 'Vehicle Count',
    }),
    columnHelper.accessor('details', {
      header: 'Details',
      Cell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <ReportVehiclesModal department_id={row.original.department_id} report_id={row.original.report_id} />
        </Box>
      ),
    }),
  ];

  const now = new Date();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const year = now.getFullYear();
        const filename = `work_flow_report_{month}_${day}_${year}`;

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename,
  });

  const exportableColumnKeys = ['dealership', 'department', 'average_time', 'number_of_vehicles'];

  const reportDealerships = useMemo(() => props.report_dealerships.split(','), [props.report_dealerships]);

  const tableData = useMemo(() => {
    // Ensure that props.data and reportDealerships are properly defined and have expected structure
    if (!props.data || !reportDealerships || !Array.isArray(reportDealerships)) {
      return [];
    }
  
    return reportDealerships.flatMap((dealership) => {
      if (!dealership || !props.data.xAxis || !Array.isArray(props.data.xAxis)) {
        return [];
      }
  
      return props.data.xAxis.map((department, index) => {
        const number_of_vehicles = props.data.xCount[dealership]?.[index] || 0;
        if (number_of_vehicles > 0) { // Only include entries with more than 0 vehicles
          return {
            dealership,
            department: department || '',
            average_time: props.data.yAxis[dealership]?.[index] || '',
            average_time_24_5: props.data.rawtime_24_5[dealership]?.[index] || '',
            average_time_24_6: props.data.rawtime_24_6[dealership]?.[index] || '',
            report_id: props.data.dataId[dealership]?.[index] || '',
            department_id: props.data.departmentId[dealership]?.[index] || '',
            goal: props.data.goal[dealership]?.[index] || '',
            report_month: props.data.xAxis[index] || '',
            number_of_vehicles,
            vehicles: [],
          };
        }
        return null; // Return null for entries with no vehicles
      }).filter(item => item !== null); // Filter out null entries
    });
  }, [props.data, reportDealerships]);
  

  const handleExportToCSV = () => {
    const rowData = tableData.map(item => exportableColumnKeys.reduce((acc, key) => {
      acc[key] = item[key];
      return acc;
    }, {}));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportToPDF = () => {
	const doc = new jsPDF();
    const tableColumn = ['Dealership', 'WorkFlow', 'Average Time', 'Number of Vehicles'];
    const tableRows = tableData.map(item => [
        item.dealership,
        props.lookupWorkFlowById(item.department_id)?.name || '',
        item.average_time,
        item.number_of_vehicles,
    ]);

    // Add the table to the PDF
    autoTable(doc, {
        head: [tableColumn],
        body: tableRows,
        startY: 10, // Start the table at position 10 on the Y-axis
    });
    doc.save(filename);
  };

  const handleExportToPDFwGraph = () => {
	const doc = new jsPDF();
	const tableColumn = ['Dealership', 'WorkFlow', 'Average Time', 'Number of Vehicles'];
	const tableRows = tableData.map(item => [
	  item.dealership,
	  props.lookupWorkFlowById(item.department_id)?.name || '',
	  item.average_time,
	  item.number_of_vehicles,
	]);
  
	// Get the summary text from the HTML element
	const summaryElement = document.getElementById('report-summary');
	const summaryText = summaryElement ? summaryElement.textContent : '';
  
	// Define the maximum width for the text to wrap
	const pageWidth = doc.internal.pageSize.getWidth();
	const margin = 10;
	const maxTextWidth = pageWidth - margin * 2;
  
	// Split the text into lines that fit within the specified width
	const wrappedText = doc.splitTextToSize(summaryText, maxTextWidth);
  
	// Add the wrapped text to the PDF
	doc.setFontSize(12);
	doc.text(wrappedText, margin, 10); // Add text at position (margin, 10)
  
	// Calculate the height of the text to position the image correctly
	const textHeight = wrappedText.length * 10; // Assuming 10 units per line height
	const startYAfterText = textHeight + 10; // Add some space after the text
  
	const element = document.getElementById('monthly-chart');
	
	if (element) {
	  // Use html2canvas to render the element to a canvas
	  html2canvas(element).then(canvas => {
		const imgData = canvas.toDataURL('image/png');
		const imgProps = doc.getImageProperties(imgData);
		const pdfWidth = doc.internal.pageSize.getWidth();
		const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
		// Add the image to the PDF below the text
		doc.addImage(imgData, 'PNG', 0, startYAfterText, pdfWidth, pdfHeight);
  
		// Add the table below the image
		autoTable(doc, {
		  head: [tableColumn],
		  body: tableRows,
		  startY: startYAfterText + pdfHeight + 10, // Start the table below the image
		});
    doc.save(filename);
	  });
	} else {
	  console.error('Element with id "monthly-chart" not found.');
	}
  };

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowSelection: false,
	enablePagination: false,
    positionToolbarAlertBanner: 'bottom',
    initialState: { showColumnFilters: false },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap' }}>
        <Button onClick={handleExportToCSV} startIcon={<FileDownloadIcon />}>
          Export to CSV
        </Button>
        <Button onClick={handleExportToPDF} startIcon={<FileDownloadIcon />}>
          Export to PDF
        </Button>
		<Button onClick={handleExportToPDFwGraph} startIcon={<FileDownloadIcon />}>
          Export to PDF(Include Graph)
        </Button>
      </Box>
    ),
  });

  var total_vehicles = 0;
		var total_times = 0;
		var total_average_times = 0;

	tableData.forEach((item) => {
			if (item.number_of_vehicles > 0) {
				total_vehicles += item.number_of_vehicles;
				total_average_times += item.average_time;
				total_times += (item.number_of_vehicles * item.average_time);
			}
	});

	var range = '(24/5)';
		if (props.dealership_report_hours === 'age-24_7') {
			range = '(24/7)';
		} else if (props.dealership_report_hours === 'age-24_6') {
			range = '(24/6)';
		} else {
			range = '(24/5)';
		}

  return (
    <ThemeProvider theme={tableTheme}>
		<p className="text-center" id="report-summary">Combined Displayed Averages: {Math.round(((total_times / total_vehicles) + Number.EPSILON) * 100) / 100} Days / Vehicles: {total_vehicles}</p>
      <MaterialReactTable table={table} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Report Vehicles"
        className="customModal"
        ariaHideApp={false}
        overlayClassName="overlay"
      >
        <div className="customCard">
          <div className="customHeader">
            <button
              type="button"
              className="btn btn-danger btn-sm float-end space-left-2"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ReportVehiclesModal work_flow_id={selectedReport.department_id} report_id={selectedReport.report_id} />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const mappedActions = {
  lookupSoldVehicleById,
  lookupVehicleByIdAllVehicles,
  getReportVehicles,
  lookupWorkFlowById,
};

function mapStateToProps(state) {
  return {
    report_dealerships: state.settings_reports.report_dealerships,
    report_data_filtered: state.settings_reports.report_data_filtered,
    report_monthly_data_filtered: state.settings_reports.report_monthly_data_filtered,
    report_vehicles: state.settings_reports.report_vehicles,
  };
}

export default connect(mapStateToProps, mappedActions)(TableWorkFlow);

