import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ButtonWithIcon from '../../inputs/ListWithIcon';
import {
	setDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	setCurrentReportTab,
	setReportWorkFlows,
	setCurrentReport,
	setReportTimeRange,
} from '../../actions/apiSetInfo';
import {
	getUser,
} from '../../actions/apiGetInfo';
import {
	change_page,
	toggleNav,
	toggleSidebar,
	toggleSidebarRight,
} from '../../actions/toggleMenu';
import {
	logout,
} from '../../actions/authentication';
import {
	lookupPermission,
	lookupWorkFlowIdByName,
	lookupSortableWorkFlowPropertiesByWorkFlow,
} from '../../actions/lookups';

class TimeDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.change_page = this.change_page.bind(this);
		this.onClickReport = this.onClickReport.bind(this);
	}

	change_page(e) {
		if (e == 'dealerships') {
			clearInterval(this.props.interval_number);
			setTimeout(() => {
				this.props.setDealership('');
				this.props.resetRefresh();
				this.props.resetNav();
				this.props.resetAdmin();
				this.props.resetSite();
				this.props.getUser();
			}, 2000);
			window.location.reload(true);
		} else if (e == 'reports') {
			this.props.setCurrentReportTab('Reports');
			this.props.change_page(e);
		} else {
			this.props.change_page(e);
		}
	}

	dealerSettingValue(name) {
		const value = _.sortBy(_.filter(this.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onClickReport(e) {
		const report_work_flows = [];
		const work_flow_id = e.target.dataset.id;
		report_work_flows.push(work_flow_id);
		this.props.setReportWorkFlows(report_work_flows.join());
		this.props.setCurrentReport('WorkFlow Averages');
		this.props.setCurrentReportTab('Reports');
		this.props.setReportTimeRange(e.target.dataset.timerange);
		this.props.change_page('reports');
	}

	getWorkFlowTime(days) {
		var day = '';
		switch (days) {
			case '7day':
				day = 'work_flow_7';
				break;
			case '14day':
				day = 'work_flow_14';
				break;
			case '30day':
				day = 'work_flow_30';
				break;
			default:
				day = '';
		}
		return day;
	}

	getWorkFlowLink(days) {
		var day = 30;
		switch (days) {
			case '7day':
				day = 7;
				break;
			case '14day':
				day = 14;
				break;
			case '30day':
				day = 30;
				break;
			default:
				day = '';
		}
		return day;
	}

	render() {
		var timedashboard = '';

		if ((this.dealerSettingValue('timedashboard') == 'true') && (this.props.departments.length > 0)) {
			if ((this.props.current_user.position == 'admin' || this.props.current_user.position == 'super_admin') || this.props.installation.database === 'marhofer' || this.props.installation.database === 'rohrman') {
				if (this.props.report_department_data.length > 0) {
					const dealership_report_hours = this.dealerSettingValue('dealership_report_hours');
					var first_digit = 0;
					var rawtime = 0;

					const header_rows = [];
					const body_rows = [];
					const footer_rows = [];

					const r = [];
					const t = [];
					const w = [];
					const d = [];
					const wd = [];
					const wdd = [];
					const vehicle_count = [];
					const report_time = [];

					for (let i = 0; i < 10; i++) {
						r[i] = this.dealerSettingValue(`timedashboard_${i}`);
						t[i] = this.dealerSettingValue(`timedashboard_${i}_time`);
						w[i] = this.props.lookupWorkFlowIdByName(r[i]);

						if (t[i] === 'Current') {
							var workflow = w[i];
							const workflow_departments = this.props.lookupSortableWorkFlowPropertiesByWorkFlow(w[i]) || [];

							var vehicle_count_total = 0;
							var report_time_mini = 0;
							workflow_departments.map((option, c) => {
								var dept = _.find(this.props.departments, { id: option.id }) || [];
								var dept_subdomain = _.find(dept.department_dealerships, { subdomain: this.props.installation.subdomain }) || false;
								if (dept_subdomain) {
									vehicle_count_total += dept_subdomain.vehicle_count;
									report_time_mini += dept_subdomain.combined_average * dept_subdomain.vehicle_count;
								}
							});

							vehicle_count[i] = vehicle_count_total;
							report_time[i] = ((report_time_mini / vehicle_count_total) / 24).toFixed(2);

							header_rows.push(<th key={`header-${i}`} className="reportHeaderDash" colSpan={2}>{r[i]}<br />({t[i]})</th>);
							body_rows.push(<td key={`body-${workflow}-${i}`} className="bg-light text-dark" colSpan={2} data-id={workflow} data-timerange={30}>{report_time[i]}</td>);
							footer_rows.push(<td key={`footer-${i}`} colSpan={2}>{vehicle_count[i]} vehicles</td>);
						} else if (r[i] !== '') {
							var workflow = w[i];
							var workflow_time = this.getWorkFlowTime(t[i]);
							var workflow_link = this.getWorkFlowLink(t[i]);
							d[i] = _.find(this.props.report_department_data, { work_flow_id: workflow, report_type: workflow_time, dealership: this.props.installation.subdomain }) || [];
							vehicle_count[i] = d[i].vehicle_count;

							if (dealership_report_hours == 'age-24_7') {
								report_time[i] = parseFloat(d[i].rawtime_24_7 / 24).toFixed(2) || 0;
							} else if (dealership_report_hours == 'age-24_6') {
								report_time[i] = parseFloat(d[i].rawtime_24_6 / 24).toFixed(2) || 0;
							} else {
								report_time[i] = parseFloat(d[i].rawtime_24_5 / 24).toFixed(2) || 0;
							}

							header_rows.push(<th key={`header-${i}`} className="reportHeaderDash" colSpan={2}>{r[i]}<br />({t[i]})</th>);
							body_rows.push(<td key={`body-${workflow}-${i}`} className="bg-light text-dark cursor-pointer" colSpan={2} data-id={workflow} data-timerange={workflow_link} onClick={this.onClickReport}>{isNaN(report_time[i])?'N/A':report_time[i]}</td>);
							footer_rows.push(<td key={`footer-${i}`} colSpan={2}>{vehicle_count[i]} vehicles</td>);
						}
					}

					timedashboard = (
						<table className="time-dashboard-table">
							<thead className="text-center">
								<tr>{header_rows}</tr>
							</thead>
							<tbody className="text-center">
								<tr>{body_rows}</tr>
							</tbody>
							<tfoot className="text-center">
								<tr>{footer_rows}</tr>
							</tfoot>
						</table>
					);
				}
			}
		}

		return (
			<>{ timedashboard }</>
		);
	}
}

const mappedActions = {
	setDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	setCurrentReportTab,
	setReportTimeRange,
	getUser,
	change_page,
	toggleSidebar,
	toggleSidebarRight,
	toggleNav,
	logout,
	lookupPermission,
	setReportWorkFlows,
	setCurrentReport,
	lookupWorkFlowIdByName,
	lookupSortableWorkFlowPropertiesByWorkFlow,
};

function mapStateToProps(state) {
	return {
		nav_status: state.settings_nav.menus.nav_status,
		report_menu_status: state.settings_nav.menus.report_menu_status,
		auth: state.current_user.isAuthenticated,
		current_user: state.current_user,
		update_status: state.update_status,
		dealer_settings: state.settings_site.dealer_settings,
		interval_number: state.settings_nav.interval_number,
		installation: state.settings_dealerships.installation,
		softname: state.settings_dealerships.installation.softname || '',
		report_department_data: state.settings_reports.report_department_data,
		departments: state.settings_departments.departments,
		inactive_status: state.settings_nav.inactive_status,
		work_flows: state.settings_departments.work_flows,
	};
}
export default connect(mapStateToProps, mappedActions)(TimeDashboard);
