import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import _ from 'lodash';
import ChartMonthly from './ChartMonthly';
import ChartDefault from './ChartDefault';
import Dropdown from '../../inputs/Dropdown';
import DropdownMultiple from '../../inputs/DropdownMultiple';
import TableDepartments from './TableDepartments';
import TableMonthly from './TableMonthly';
import TableWorkFlow from './TableWorkFlow';
import TableDepartmentsMonthly from './TableDepartmentsMonthly';
import { getInitialReportSettings } from '../../actions/apiGetInfo';
import { readableStampSimple, readableStampSimpleMonthYear } from '../../utils/dateFunctions';
import { toggleReportNav} from '../../actions/toggleMenu';
import { lookupWorkFlowById, lookupDepartmentNameById } from '../../actions/lookups';
import LoadingSpinner from '../../components/LoadingSpinner/loadingSpinner';

const ButtonGroup = ({ name, icon, onClick, active }) => (
  <button
    className={active ? 'btn btn-success active pad-top-1 pad-bottom-1 shadow_2' : 'btn btn-default pad-top-1 pad-bottom-1 shadow_2'}
    name={name}
    onClick={onClick}
  >
    <i className={`${icon} float-left`} />
    {name}
  </button>
);

const formatReportType = (reportType) => {
  const match = reportType.match(/work_flow_(\d{4})_(\d{2})/);
  if (match) {
    const year = match[1].slice(2);
    const month = match[2];
    return `${month}/${year}`;
  }
  return '';
};

const ReportDashboard = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  const {
    report_start_date,
    report_end_date,
    report_dealerships,
    report_departments,
    related_installations,
    current_report,
    work_flows,
    dealer_settings,
    report_months,
    monthly_report_departments,
    monthly_report_work_flows,
    report_work_flows,
    report_filters,
    all_filters,
    report_department_data,
    report_time_range,
    report_menu_status,
    departments,
  } = useSelector((state) => ({
    ...state.settings_reports,
    ...state.settings_dealerships,
    ...state.settings_site,
    ...state.settings_nav.menus,
    departments: state.settings_departments.departments,
    work_flows: state.settings_departments.work_flows,
  }));

  useEffect(() => {
    dispatch(getInitialReportSettings())
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error('Failed to fetch settings:', error);
        setIsLoading(false);
      });
  }, [dispatch]);

  const dealerSettingValue = (name) => {
    const value = _.sortBy(_.filter(dealer_settings, (o) => o.key === name), 'id');
    return value.length > 0 && value[0].value !== undefined ? value[0].value : '';
  };

  const selectRelatedInstallations = useMemo(() => {
    const selectedDealerships = report_dealerships.split(',');
    return related_installations.map((option) => ({
      id: option.id,
      label: option.softname || option.subdomain,
      subdomain: option.subdomain,
      selected: selectedDealerships.includes(option.subdomain),
      dataid: 0,
    }));
  }, [report_dealerships, related_installations]);

  const selectDepartments = useMemo(() => {
    const departmentIds = report_departments ? report_departments.split(',') : [];
    return departments.filter(option => option.department_type === 'department').map(option => ({
      id: option.id,
      label: option.name,
      selected: departmentIds.includes(option.id.toString()),
    }));
  }, [report_departments, departments]);

  const selectWorkFlows = useMemo(() => {
    const reportWorkFlows = report_work_flows.split(',');
    return work_flows.map((option) => ({
      id: option.id,
      label: option.name,
      selected: reportWorkFlows.includes(option.id.toString()),
    }));
  }, [report_work_flows, work_flows]);

  const selectMonthlyWorkFlows = useMemo(() => work_flows.map((option) => ({
      id: option.id,
      label: option.name,
      selected: monthly_report_work_flows == option.id,
    })), [monthly_report_work_flows, work_flows]);

  const selectMonthlyDepartments = useMemo(() => departments.filter(option => option.department_type === 'department').map(option => ({
      id: option.id,
      label: option.name,
      selected: monthly_report_departments == option.id,
    })), [monthly_report_departments, departments]);

  const selectFilters = useMemo(() => {
    const reportFilters = report_filters.split(',');
    return all_filters.map((option) => ({
      id: option.id,
      label: option.filter_name,
      selected: reportFilters.includes(option.id.toString()),
    }));
  }, [report_filters, all_filters]);

  const selectTimeRange = useMemo(() => {
    const d = new Date();
    const last = d.getMonth() - 1;
    const second = d.getMonth() - 2;
    const third = d.getMonth() - 3;
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const adjustMonth = (month) => (month < 0 ? month + 12 : month);

    const lastMonth = months[adjustMonth(last)];
    const secondMonth = months[adjustMonth(second)];
    const thirdMonth = months[adjustMonth(third)];

    const comparisonArray = [
      { id: 2, name: 'Month to Date', label: 'Month to Date', value: 'Month to Date' },
      { id: 3, name: 'last', label: lastMonth, value: lastMonth },
      { id: 4, name: 'second', label: secondMonth, value: secondMonth },
      { id: 5, name: 'third', label: thirdMonth, value: thirdMonth },
      { id: 6, name: '90', label: 'Last 90 days', value: 'Last 90 days' },
      { id: 7, name: '60', label: 'Last 60 days', value: 'Last 60 days' },
      { id: 8, name: '30', label: 'Last 30 days', value: 'Last 30 days' },
      { id: 9, name: '14', label: 'Last 14 days', value: 'Last 14 days' },
      { id: 10, name: '7', label: 'Last 7 days', value: 'Last 7 days' },
    ];

    return comparisonArray.map((option) => ({
      id: option.id,
      name: option.name,
      value: option.value,
      label: option.label,
      selected: report_time_range === option.name,
    }));
  }, [report_time_range]);

  const selectTimeRangeMonths = useMemo(() => {
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonth = d.getMonth() + 1; // JavaScript months are 0-based
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const getPastMonthYear = (offset) => {
      let year = currentYear;
      let month = currentMonth - offset;
      if (month <= 0) {
        month += 12;
        year -= 1;
      }
      return { year, month: month.toString().padStart(2, '0') };
    };

    const comparisonArray = [
      { id: 1, name: 'Month to Date', label: 'Month to Date', value: 'Month to Date' },
      ...Array.from({ length: 12 }, (_, i) => {
        const { year, month } = getPastMonthYear(i + 1);
        return {
          id: i + 2,
          name: `work_flow_${year}_${month}`,
          label: `${months[parseInt(month, 10) - 1]} ${year}`,
          value: `work_flow_${year}_${month}`,
        };
      }),
    ];

    return comparisonArray.map((option) => {
      const selectedMonths = report_months ? report_months.split(',') : [];
      const selected = selectedMonths.includes(option.name);
      return {
        id: option.id,
        name: option.name,
        value: option.value,
        label: option.label,
        selected,
      };
    });
  }, [report_months]);

  const selectTimeRangeStepMonths = useMemo(() => {
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonth = d.getMonth() + 1; // JavaScript months are 0-based
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const getPastMonthYear = (offset) => {
      let year = currentYear;
      let month = currentMonth - offset;
      if (month <= 0) {
        month += 12;
        year -= 1;
      }
      return { year, month: month.toString().padStart(2, '0') };
    };

    const comparisonArray = [
      { id: 1, name: 'Month to Date', label: 'Month to Date', value: 'Month to Date' },
      ...Array.from({ length: 12 }, (_, i) => {
        const { year, month } = getPastMonthYear(i + 1);
        return {
          id: i + 2,
          name: `step_${year}_${month}`,
          label: `${months[parseInt(month, 10) - 1]} ${year}`,
          value: `step_${year}_${month}`,
        };
      }),
    ];

    return comparisonArray.map((option) => {
      const selectedMonths = report_months ? report_months.split(',') : [];
      const selected = selectedMonths.includes(option.name);
      return {
        id: option.id,
        name: option.name,
        value: option.value,
        label: option.label,
        selected,
      };
    });
  }, [report_months]);

  const selectCurrentReport = useMemo(() => {
    const comparisonArray = [
      { id: 1, name: 'Step Averages', label: 'Step Averages' },
      { id: 2, name: 'Step Averages (monthly)', label: 'Step Averages (monthly)' },
      { id: 3, name: 'WorkFlow Averages', label: 'WorkFlow Averages' },
      { id: 4, name: 'WorkFlow Averages (monthly)', label: 'WorkFlow Averages (monthly)' },
    ];

    return comparisonArray.map((option) => ({
      id: option.id,
      label: option.label,
      selected: current_report === option.name,
    }));
  }, [current_report]);

  const reportTitle = useMemo(() => {
    if (current_report === 'WorkFlow Averages (monthly)') {
      return `${current_report} | ${
        props.lookupWorkFlowById(monthly_report_work_flows)?.name || ''
      }`;
    } if (current_report === 'Step Averages (monthly)') {
      return `${current_report} | ${
        props.lookupDepartmentNameById(monthly_report_departments) || ''
      }`;
    } if (current_report === 'Step Averages' || current_report === 'WorkFlow Averages') {
      return `${current_report} | ${readableStampSimple(
        report_start_date
      )} - ${readableStampSimple(report_end_date)}`;
    }
    return '';
  }, [current_report, monthly_report_work_flows, monthly_report_departments, report_start_date, report_end_date]);

  const dropdowns = useMemo(() => {
    const dropdownElements = [];
    if (current_report === 'WorkFlow Averages (monthly)') {
      const location = (
        <li className="nav-item" key="location">
          <div className="col">
            <small>Location:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Location"
              title="Locations"
              list={selectRelatedInstallations}
              update_type={'report_dealerships'}
              report_dealerships={report_dealerships}
            />
          </div>
        </li>
      );

      if (related_installations.length !== 1) {
        dropdownElements.push(location);
      }

      dropdownElements.push(
        <li className="nav-item" key="work_flows">
          <div className="col">
            <small>WorkFlow:</small>
            <br />
            <Dropdown
            {...props}
              titleHelper="WorkFlow"
              title="Choose WorkFlow"
              list={selectMonthlyWorkFlows}
              update_type={'set_monthly_report_work_flows'}
              monthly_report_work_flows={monthly_report_work_flows}
            />
          </div>
        </li>
      );
      dropdownElements.push(
        <li className="nav-item" key="work_flow_months">
          <div className="col">
            <small>Report Months:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Report Months"
              title="Choose Months"
              list={selectTimeRangeMonths}
              update_type={'report_months'}
              value={report_months}
            />
          </div>
        </li>
      );
    } else if (current_report === 'Step Averages (monthly)') {
      const location = (
        <li className="nav-item" key="location">
          <div className="col">
            <small>Location:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Location"
              title="Locations"
              list={selectRelatedInstallations}
              update_type={'report_dealerships'}
              report_dealerships={report_dealerships}
            />
          </div>
        </li>
      );

      if (related_installations.length !== 1) {
        dropdownElements.push(location);
      }

      dropdownElements.push(
        <li className="nav-item" key="steps">
          <div className="col">
            <small>Step:</small>
            <br />
            <Dropdown
            {...props}
              titleHelper="Step"
              title="Choose Step"
              list={selectMonthlyDepartments}
              update_type={'set_monthly_report_departments'}
              monthly_report_departments={monthly_report_departments}
            />
          </div>
        </li>
      );
      dropdownElements.push(
        <li className="nav-item" key="step_months">
          <div className="col">
            <small>Report Months:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Report Months"
              title="Choose Months"
              list={selectTimeRangeStepMonths}
              update_type={'report_months'}
              value={report_months}
            />
          </div>
        </li>
      );
    } else if (current_report === 'Step Averages') {
      const location = (
        <li className="nav-item" key="location">
          <div className="col">
            <small>Location:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Location"
              title="Locations"
              list={selectRelatedInstallations}
              update_type={'report_dealerships'}
              report_dealerships={report_dealerships}
            />
          </div>
        </li>
      );

      if (related_installations.length !== 1) {
        dropdownElements.push(location);
      }

      dropdownElements.push(
        <li className="nav-item" key="steps">
          <div className="col">
            <small>Steps:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Step"
              title="Steps"
              list={selectDepartments}
              update_type={'report_departments'}
              report_departments={report_departments}
            />
          </div>
        </li>
      );
      dropdownElements.push(
        <li className="nav-item" key="time_range">
          <div className="col">
            <small>Time Range:</small>
            <br />
            <Dropdown
            {...props}
              titleHelper="Time Range"
              title="Range"
              list={selectTimeRange}
              update_type={'report_time_range'}
              value={report_time_range}
            />
          </div>
        </li>
      );
    } else if (current_report === 'WorkFlow Averages') {
      const location = (
        <li className="nav-item" key="location">
          <div className="col">
            <small>Location:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="Location"
              title="Locations"
              list={selectRelatedInstallations}
              update_type={'report_dealerships'}
              report_dealerships={report_dealerships}
            />
          </div>
        </li>
      );

      if (related_installations.length !== 1) {
        dropdownElements.push(location);
      }

      dropdownElements.push(
        <li className="nav-item" key="work_flows">
          <div className="col">
            <small>WorkFlows:</small>
            <br />
            <DropdownMultiple
            {...props}
              titleHelper="WorkFlow"
              title="WorkFlows"
              list={selectWorkFlows}
              update_type={'report_work_flows'}
              report_work_flows={report_work_flows}
            />
          </div>
        </li>
      );
      dropdownElements.push(
        <li className="nav-item" key="time_range">
          <div className="col">
            <small>Time Range:</small>
            <br />
            <Dropdown
            {...props}
              titleHelper="Time Range"
              title="Range"
              list={selectTimeRange}
              update_type={'report_time_range'}
              value={report_time_range}
            />
          </div>
        </li>
      );
    }
    return dropdownElements;
  }, [props, current_report, report_departments, report_dealerships, related_installations, monthly_report_work_flows, report_months, monthly_report_departments, work_flows, report_work_flows, report_time_range]);

  const reportData = useMemo(() => {
    const data = {
      yAxis: [],
      rawtime_24_5: [],
      rawtime_24_6: [],
      xCount: [],
      xAxis: [],
      dataId: [],
      departmentId: [],
      goal: [],
    };

    const reportDealerships = report_dealerships.split(',');
    const reportWorkFlows = report_work_flows.split(',');

    let reportType = 'step-30';
    let reportTypeMonthToDate = 'work_flow_Month to Date';

    if (current_report === 'Step Averages') {
      reportType = `step-${report_time_range}`;
    } else if (current_report === 'Step Averages (monthly)') {
      reportTypeMonthToDate = 'step-Month to Date';
      reportType = 'step_monthly';
    } else if (current_report === 'WorkFlow Averages') {
      reportType = `work_flow_${report_time_range}`;
    } else if (current_report === 'WorkFlow Averages (monthly)') {
      reportType = 'work_flow_monthly';
      reportTypeMonthToDate = 'work_flow_Month to Date';
    }

    const dealershipReportHours = dealerSettingValue('dealership_report_hours');

    if (reportType.includes('work_flow_monthly')) {
      reportDealerships.forEach((dealership) => {
        data.xCount[dealership] = [];
        data.yAxis[dealership] = [];
        data.rawtime_24_5[dealership] = [];
        data.rawtime_24_6[dealership] = [];
        data.dataId[dealership] = [];
        data.departmentId[dealership] = [];
        data.goal[dealership] = [];

        report_department_data.forEach((option) => {
          if (
            dealership === option.dealership &&
            (report_months.includes(option.report_type) || (reportTypeMonthToDate == option.report_type)) &&
            monthly_report_work_flows != '' &&
            monthly_report_work_flows == option.work_flow_id
            && Number(option.vehicle_count) > 0
          ) {
            data.xCount[dealership].push(option.vehicle_count || 0);
            data.xAxis.push(readableStampSimpleMonthYear(option.recon_end_date));
            const rawtime_24_7 = (option.rawtime_24_7 / 24).toFixed(2);
            const rawtime_24_6 = (option.rawtime_24_6 / 24).toFixed(2);
            const rawtime_24_5 = (option.rawtime_24_5 / 24).toFixed(2);
            data.rawtime_24_5[dealership].push(Number(rawtime_24_5));
            data.rawtime_24_6[dealership].push(Number(rawtime_24_6));

            if (dealershipReportHours === 'age-24_7') {
              data.yAxis[dealership].push(Number(rawtime_24_7));
            } else if (dealershipReportHours === 'age-24_6') {
              data.yAxis[dealership].push(Number(rawtime_24_6));
            } else {
              data.yAxis[dealership].push(Number(rawtime_24_5));
            }

            data.dataId[dealership].push(option.id);
            data.departmentId[dealership].push(option.work_flow_id);
            data.goal[dealership].push(option.goal);
          }
        });
      });
    } else if (reportType.includes('work_flow_')) {
      reportDealerships.forEach((dealership) => {
        data.xCount[dealership] = [];
        data.yAxis[dealership] = [];
        data.rawtime_24_5[dealership] = [];
        data.rawtime_24_6[dealership] = [];
        data.dataId[dealership] = [];
        data.departmentId[dealership] = [];
        data.goal[dealership] = [];

        report_department_data.forEach((option) => {
          if (
            dealership === option.dealership &&
            report_work_flows.includes(option.work_flow_id.toString()) &&
            reportType === option.report_type &&
            Number(option.vehicle_count) > 0
          ) {
            data.xCount[dealership].push(option.vehicle_count || 0);
            data.xAxis.push(props.lookupWorkFlowById(option.work_flow_id)?.name);
            const rawtime_24_7 = (option.rawtime_24_7 / 24).toFixed(2);
            const rawtime_24_6 = (option.rawtime_24_6 / 24).toFixed(2);
            const rawtime_24_5 = (option.rawtime_24_5 / 24).toFixed(2);
            data.rawtime_24_5[dealership].push(Number(rawtime_24_5));
            data.rawtime_24_6[dealership].push(Number(rawtime_24_6));

            if (dealershipReportHours === 'age-24_7') {
              data.yAxis[dealership].push(Number(rawtime_24_7));
            } else if (dealershipReportHours === 'age-24_6') {
              data.yAxis[dealership].push(Number(rawtime_24_6));
            } else {
              data.yAxis[dealership].push(Number(rawtime_24_5));
            }

            data.dataId[dealership].push(option.id);
            data.departmentId[dealership].push(option.work_flow_id);
            data.goal[dealership].push(option.goal);
          }
        });
      });
    } else if (reportType.includes('step_monthly')) {
      reportDealerships.forEach((dealership) => {
        data.xCount[dealership] = [];
        data.yAxis[dealership] = [];
        data.rawtime_24_5[dealership] = [];
        data.rawtime_24_6[dealership] = [];
        data.dataId[dealership] = [];
        data.departmentId[dealership] = [];
        data.goal[dealership] = [];

        report_department_data.forEach((option) => {
          if (
            option.department_type == 'department' &&
            dealership === option.dealership &&
            (report_months.includes(option.report_type) || (reportTypeMonthToDate == option.report_type)) &&
            monthly_report_departments == option.department_id &&
            Number(option.vehicle_count) > 0
          ) {
            data.xCount[dealership].push(option.vehicle_count || 0);
            data.xAxis.push(readableStampSimpleMonthYear(option.recon_end_date));
            const rawtime_24_7 = (option.rawtime_24_7 / 24).toFixed(2);
            const rawtime_24_6 = (option.rawtime_24_6 / 24).toFixed(2);
            const rawtime_24_5 = (option.rawtime_24_5 / 24).toFixed(2);
            data.rawtime_24_5[dealership].push(Number(rawtime_24_5));
            data.rawtime_24_6[dealership].push(Number(rawtime_24_6));

            if (dealershipReportHours === 'age-24_7') {
              data.yAxis[dealership].push(Number(rawtime_24_7));
            } else if (dealershipReportHours === 'age-24_6') {
              data.yAxis[dealership].push(Number(rawtime_24_6));
            } else {
              data.yAxis[dealership].push(Number(rawtime_24_5));
            }

            data.dataId[dealership].push(option.id);
            data.departmentId[dealership].push(option.department_id);
            data.goal[dealership].push(option.goal);
          }
        });
      });
    } else {
      reportDealerships.forEach((dealership) => {
        data.xCount[dealership] = [];
        data.yAxis[dealership] = [];
        data.rawtime_24_5[dealership] = [];
        data.rawtime_24_6[dealership] = [];
        data.dataId[dealership] = [];
        data.departmentId[dealership] = [];
        data.goal[dealership] = [];
        report_department_data.forEach((option) => {
          if (
            option.department_type == 'department' &&
            dealership === option.dealership &&
            reportType === option.report_type &&
            report_departments.includes(option.department_id.toString()) &&
            Number(option.vehicle_count) > 0
          ) {
            data.xCount[dealership].push(option.vehicle_count || 0);
            data.xAxis.push(option.department_name);
            const rawtime_24_7 = (option.rawtime_24_7 / 24).toFixed(2);
            const rawtime_24_6 = (option.rawtime_24_6 / 24).toFixed(2);
            const rawtime_24_5 = (option.rawtime_24_5 / 24).toFixed(2);
            data.rawtime_24_5[dealership].push(Number(rawtime_24_5));
            data.rawtime_24_6[dealership].push(Number(rawtime_24_6));

            if (dealershipReportHours === 'age-24_7') {
              data.yAxis[dealership].push(Number(rawtime_24_7));
            } else if (dealershipReportHours === 'age-24_6') {
              data.yAxis[dealership].push(Number(rawtime_24_6));
            } else {
              data.yAxis[dealership].push(Number(rawtime_24_5));
            }

            data.dataId[dealership].push(option.id);
            data.departmentId[dealership].push(option.department_id);
            data.goal[dealership].push(option.goal);
          }
        });
      });
    }

    return data;
  }, [report_departments, report_dealerships, report_work_flows, current_report, report_time_range, report_months, monthly_report_work_flows, monthly_report_departments, report_department_data]);

  return (
    <div className="container col-md-12 reportbox bg-light mobile-pad-left-2">
      <div className="navbar navbar-expand-lg navbar-light bg-light space-top-1 space-bottom-1">
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => dispatch(toggleReportNav())}
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className={`${report_menu_status ? 'row' : 'collapse row '} navbar-collapse mobile-pad-left-2`}>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item" key="choose_report">
              <div className="col">
                <small>Choose Report: </small>
                <br />
                <Dropdown
                  {...props}
                  titleHelper="Report"
                  title="Report"
                  list={selectCurrentReport}
                  update_type={'set_current_report'}
                  value={current_report}
                />
              </div>
            </li>
            {isLoading ? '' : dropdowns }
          </ul>
          <div className="row">
            <div className="col-md-5 pad-left-1">
              {report_department_data.length > 0 ? (
                (() => {
                  switch (current_report) {
                    case 'WorkFlow Averages':
                      return (
                        <ChartDefault
                          key={reportTitle}
                          report_title={reportTitle}
                          data={reportData}
                          container="chart"
                          current_report={current_report}
                        />
                      );
                    case 'Step Averages':
                      return (
                        <ChartDefault
                          key={reportTitle}
                          report_title={reportTitle}
                          data={reportData}
                          container="chart"
                          current_report={current_report}
                        />
                      );
                    case 'WorkFlow Averages (monthly)':
                      return (
                        <ChartMonthly
                          key={reportTitle}
                          report_title={reportTitle}
                          data={reportData}
                          container="chart"
                          current_report={current_report}
                        />
                      );
                    case 'Step Averages (monthly)':
                      return (
                        <ChartDefault
                          key={reportTitle}
                          report_title={reportTitle}
                          data={reportData}
                          container="chart"
                          current_report={current_report}
                        />
                      );
                    default:
                      return <div>No report available</div>;
                  }
                })()
              ) : (
                <div className="row text-center bg-dark">
                  <LoadingSpinner loading />
                </div>
              )}
            </div>
            <div className="col-md-7 bg-light">
              {report_department_data.length > 0 ? (
                (() => {
                  switch (current_report) {
                    case 'WorkFlow Averages':
                      return (
                        <TableWorkFlow
                          data={reportData}
                          dealership_report_hours={dealerSettingValue('dealership_report_hours')}
                        />
                      );
                    case 'WorkFlow Averages (monthly)':
                      return (
                        <TableMonthly
                          data={reportData}
                          dealership_report_hours={dealerSettingValue('dealership_report_hours')}
                        />
                      );
                    case 'Step Averages (monthly)':
                      return (
                        <TableDepartmentsMonthly
                          data={reportData}
                          dealership_report_hours={dealerSettingValue('dealership_report_hours')}
                        />
                      );
                    default:
                      return (
                        <TableDepartments
                          data={reportData}
                          dealership_report_hours={dealerSettingValue('dealership_report_hours')}
                        />
                      );
                  }
                })()
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mappedActions = {
  toggleReportNav,
  lookupWorkFlowById,
  lookupDepartmentNameById,
};

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps, mappedActions)(ReportDashboard);