import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import VehicleHistorySummary from './VehicleHistorySummary';
import ReportVehicles from './ReportWorkFlowVehicles';
import { getReportVehicles } from '../../actions/apiGetInfo';
import { lookupVehicleByIdAllVehicles } from '../../actions/lookups';

class ReportWorkFlowVehiclesModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, report_id: this.props.report_id, department_id: this.props.department_id,
		};
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal(report_id, department_id) {
		if (report_id > 0) {
			this.props.getReportVehicles(report_id);
		this.setState({ report_id, department_id, modalIsOpen: true });
		}
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	render() {
		const { errors } = this.state;
        const onClickHandler = () => this.openModal(this.props.report_id, this.props.department_id);
		return (
			<div className="col-md-3">
				<Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                contentLabel="Report Vehicles"
                className="customModal"
                ariaHideApp={false}
                overlayClassName="overlay"
                >
                <div className="customCard">
                <div className="customHeader">
					<button type="button" className="btn btn-danger btn-sm float-end space-left-2" ariaLabel="Close" onClick={this.closeModal}>
                        <span ariaHidden="true">&times;</span>
                    </button>
					</div>
                    <ReportVehicles work_flow_id={this.state.department_id} department_id={this.state.department_id} report_id={this.state.report_id} />
                </div>
                </Modal>
				<div className="btn-group">
					<button className="btn btn-sm btn-success" onClick={onClickHandler}><i className="fal fa-car" /></button>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupVehicleByIdAllVehicles,
    getReportVehicles,
};

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps, mappedActions)(ReportWorkFlowVehiclesModal);