import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';

const ChartMonthly = ({ report_dealerships, report_title, data }) => {
  const chartRef = useRef(null);

  // Memoize the series data to avoid unnecessary recalculations
  const seriesArray = useMemo(() => {
    return report_dealerships.split(',').map((dealership) => {
      if (dealership) {
        const seriesData = data.yAxis?.[dealership] || [];
        return {
          name: dealership,
          data: seriesData,
          tooltip: {
            valueSuffix: ' Days',
          },
        };
      }
      return null;
    }).filter(Boolean);
  }, [report_dealerships, data]);

  useEffect(() => {
    if (!data.xAxis) {
      console.error('Invalid data: xAxis is undefined');
      return;
    }

    // Render the chart
    chartRef.current = Highcharts.chart('monthly-chart', {
      chart: {
        type: 'column',
        backgroundColor: null,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: report_title,
      },
      xAxis: {
        categories: data.xAxis,
        crosshair: true,
        labels: {
          style: {
            color: 'black',
          },
        },
      },
      yAxis: {
        labels: {
          format: '{value} Days',
          style: {
            color: 'black',
          },
        },
        title: {
          text: 'Average Recon Times',
        },
      },
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        itemStyle: {
          color: 'black',
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#000',
            align: 'right',
            format: '{point.y:.1f}',
            y: 10,
            style: {
              fontSize: '13px',
              color: '#000',
              fontFamily: 'Verdana, sans-serif',
            },
          },
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click() {
                alert(`Department: ${this.category}, Days: ${this.y}`);
              },
            },
          },
        },
      },
      series: seriesArray,
    });

    // Cleanup function to dispose of the chart
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [seriesArray, report_title, data.xAxis]); // Dependencies for useEffect

  return (
    <div className="container space-top-1 space-bottom-1 pad-bottom-1 pad-top-2 bg-light shadow_4">
      <div id="monthly-chart" />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    report_dealerships: state.settings_reports.report_dealerships || '',
  };
}

export default connect(mapStateToProps)(ChartMonthly);