import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
} from 'material-react-table';
import {
  Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import ReportSingleVehicle from './ReportSingleVehicle';
import {
  getReportVehicles,
} from '../../actions/apiGetInfo';
import { convertSecondsToTime, noteStamp } from '../../utils/dateFunctions';
import _ from 'lodash';

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor('stock', {
    header: 'Stock',
    size: 120,
  }),
  columnHelper.accessor('year', {
    header: 'Vehicle',
  }),
  columnHelper.accessor('starttime', {
    header: 'StartTime',
  }),
  columnHelper.accessor('endtime', {
    header: 'EndTime',
  }),
  columnHelper.accessor('time', {
    header: 'Time',
    filterVariant: 'range',
    filterFn: 'between',
  }),
  columnHelper.accessor('details', {
    header: 'Details',
    Cell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <ReportSingleVehicle vehicle={row.original.vehicle[0]} />
      </Box>
    ),
  }),
];

const now = new Date();
const month = (now.getMonth() + 1).toString().padStart(2, '0');
const day = now.getDate().toString().padStart(2, '0');
const year = now.getFullYear();
const filename = `report_work_flow_vehicles_${month}_${day}_${year}`;

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename,
  });

const exportableColumnKeys = ['stock', 'year', 'starttime', 'endtime', 'time'];

const ExportCSV = (props) => {
  const globalTheme = useTheme();

  const tableTheme = useMemo(() => createTheme({
    palette: {
      mode: 'light',
    },
  }), [globalTheme]);

  // Prepare data for the table
  const data = useMemo(() => {
    const newdata = [];
    props.report_vehicles.map((vehicle) => {
      const vehicle_histories = _.filter(vehicle.vehicle_histories, o => o.recon_work_flow_id === parseInt(props.work_flow_id));
      vehicle_histories.map((vehicle_history) => {
        newdata.push({
          stock: vehicle.stock,
          year: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
          starttime: noteStamp(vehicle_history.recon_starttime),
          endtime: noteStamp(vehicle_history.recon_endtime),
          time: convertSecondsToTime(vehicle_history.recon_24_7),
          vehicle: [vehicle],
        });
      });
    });
    return newdata;
  }, [props.report_vehicles, props.work_flow_id]);

  const handleExportRowsToCSV = (rows) => {
    const rowData = rows.map(row => exportableColumnKeys.reduce((acc, key) => {
      acc[key] = row.original[key];
      return acc;
    }, {}));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportDataToCSV = () => {
    const rowData = data.map(item => exportableColumnKeys.reduce((acc, key) => {
      acc[key] = item[key];
      return acc;
    }, {}));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRowsToPDF = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map(row => exportableColumnKeys.map(key => row.original[key]));
    const tableHeaders = exportableColumnKeys.map(key => columns.find(col => col.accessorKey === key).header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    positionToolbarAlertBanner: 'bottom',
    initialState: { showColumnFilters: false },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRowsToCSV(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export to CSV
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRowsToCSV(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows to CSV
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRowsToPDF(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export to PDF
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRowsToPDF(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows to PDF
        </Button>
      </Box>
    ),
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

const mappedActions = {
  getReportVehicles,
  convertSecondsToTime,
  noteStamp,
};

function mapStateToProps(state) {
  return {
    report_vehicles: state.settings_reports.report_vehicles,
  };
}

export default connect(mapStateToProps, mappedActions)(ExportCSV);